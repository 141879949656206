// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()



const userIcon = document.querySelector('.logged-in-user');
const userMenu = document.querySelector('.logged-in-user__menu');

function toggleMenu() {
  userMenu.classList.toggle('is-active');
}

if(userIcon) {
  userIcon.addEventListener('click', () => { toggleMenu() });
}


// Close the dropdown menu if the user clicks outside of it
window.onclick = function(event) {
  if (!event.target.classList.contains('logged-in-user') && !event.target.classList.contains('logged-in-user__initials')) {
    var dropdowns = document.getElementsByClassName("logged-in-user__menu");
    var i;

    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('is-active')) {
        openDropdown.classList.remove('is-active');
      }
    }
  }
}
